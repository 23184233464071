import { UI } from '@carfluent/common'
import { type ElementType, type ReactNode, type MouseEvent, type RefObject } from 'react'
import { type OutletProps } from 'react-router-dom'
import { type TypographyProps } from '@material-ui/core'

import { type BusinessHours } from 'website/api/schedule.types'
import { type GetPrequalifiedResponse } from 'website/api/types/financing'
import { ServiceType } from 'website/api/types'
import { IconProps } from 'website/components/types'

import {
  type ComponentInfo,
  type CustomizableComponent,
  type TemplateProps,
  type TemplateConfigs as BaseTemplateConfigs
} from 'website/siteGenerator/types'

import { SupportedComponents, SupportedComponentNames } from './enums'
import { type SvgIconProps, type IconTypes } from './icons'
import { type TypographyVariant } from './styles'

export type ComponentPropsMap = {
  [SupportedComponents.Header]: HeaderProps
  [SupportedComponents.FooterInfo]: FooterInfoProps
  [SupportedComponents.ExtendedImage]: ExtendedImageProps
  [SupportedComponents.ExtendedTypography]: ExtendedTypographyProps
  [SupportedComponents.LazyImage]: LazyImageProps
  [SupportedComponents.CarBodyStylePanel]: CarBodyStylePanelProps
  [SupportedComponents.Login]: LoginProps
  [SupportedComponents.Carousel]: CarouselProps
  [SupportedComponents.Filler]: FillerProps
  [SupportedComponents.DrawerMenu]: DrawerMenuProps
  [SupportedComponents.RouteOutlet]: RouteOutletProps
  [SupportedComponents.DynamicComponent]: DynamicComponentProps
  [SupportedComponents.FiltersDrawerToggler]: FiltersDrawerTogglerProps
  [SupportedComponents.Socials]: SocialsProps
  [SupportedComponents.Schedule]: ScheduleProps
  [SupportedComponents.Map]: MapProps
  [SupportedComponents.MainSearchSection]: MainSearchSectionProps
  [SupportedComponents.VehicleCard]: VehicleCardProps
  [SupportedComponents.RecommendedVehicles]: RecommendedVehiclesProps
  [SupportedComponents.SearchBar]: SearchBarProps
  [SupportedComponents.Button]: ButtonProps
  [SupportedComponents.Input]: InputProps
  [SupportedComponents.VehicleInfiniteScroll]: VehicleInfiniteScrollProps
  [SupportedComponents.VehiclesFilter]: VehiclesFilterProps
  [SupportedComponents.VehiclesFilterChips]: VehiclesFilterChipsProps
  [SupportedComponents.VehiclesSorting]: VehiclesSortingProps
  [SupportedComponents.VehiclesSearch]: VehiclesSearchProps
  [SupportedComponents.DealerInfo]: DealerInfoProps
  [SupportedComponents.Text]: TextProps
  [SupportedComponents.TooltipText]: TooltipTextProps
  [SupportedComponents.List]: ListProps
  [SupportedComponents.VehicleViewContent]: VehicleViewContentProps
  [SupportedComponents.GalleryVehicleView]: GalleryVehicleViewProps
  [SupportedComponents.VehicleBriefInfo]: VehicleBriefInfoProps
  [SupportedComponents.IncludedWithCarAds]: IncludedWithCarAdsProps
  [SupportedComponents.VehicleSpecsOverview]: VehicleSpecsOverviewProps
  [SupportedComponents.RequestHelpOrDrive]: RequestHelpOrDriveProps
  [SupportedComponents.TextWithFiller]: TextWithFillerProps
  [SupportedComponents.GetPrequalifiedComponent]: GetPrequalifiedComponentProps
  [SupportedComponents.GetPrequalifiedRedirectComponent]: GetPrequalifiedRedirectComponentProps
  [SupportedComponents.GetPrequalifiedForm]: GetPrequalifiedFormProps
  [SupportedComponents.UnsubscribeButton]: UnsubscribeButtonProps
  [SupportedComponents.GetPrequalifiedSuccess]: GetPrequalifiedSuccessProps
  [SupportedComponents.Modal]: ModalProps
  [SupportedComponents.CarAppraisalForm]: CarAppraisalFormProps
  [SupportedComponents.Banner]: BannerProps
  [SupportedComponents.CustomerReviews]: CustomerReviewsProps
  [SupportedComponents.VinStickerButton]: VinStickerButtonProps
  [SupportedComponents.TradeInDetailsFormPage]: TradeInDetailsFormPageProps
  [SupportedComponents.TradeInCar]: TradeInCarProps
  [SupportedComponents.TradeInUserDetailsForm]: TradeInUserDetailsFormProps
  [SupportedComponents.ZipCodeLocation]: ZipCodeLocationProps
  [SupportedComponents.ZipCodeLocationModal]: ZipCodeLocationModalProps
  [SupportedComponents.FeatureOptionList]: FeatureOptionListProps
  [SupportedComponents.SsnYearIncomeForm]: SsnYearIncomeFormProps
  [SupportedComponents.FeedbackForm]: FeedbackFormProps
  [SupportedComponents.Messenger]: MessengerProps
  [SupportedComponents.GetPrequalifiedDisclosure]: GetPrequalifiedDisclosureProps
  [SupportedComponents.DealerLocationsList]: DealerLocationsListProps
  [SupportedComponents.ExtendedIconSVG]: ExtendedIconSVGProps
  [SupportedComponents.DescriptionsCarousel]: DescriptionsCarouselProps
  [SupportedComponents.CarUnderPriceList]: CarUnderPriceListProps
  /**
   * FIX-ME: replace 'any' with a better type.
   * DD-NOTE: wanted to union with Record<string, DynamicComponentProps>
   * but then it complains that some component have not sufficient types
   * to be compatible.
   */
} & Record<string, any>

export type SupportedStyledComponents = SupportedComponents | SupportedComponentNames

export type TemplateConfigs = BaseTemplateConfigs<ComponentPropsMap>

export { SupportedComponents, SupportedComponentNames } from './enums'
export { type IconTypes, type SvgIconProps, type IconProps } from './icons'

// #region

// ========================================== //
//           COMMON COMPONENT TYPES           //
// ========================================== //

export type Breakpoints = [number, number?]

// #endregion

// #region
// ========================================== //
//               BASE COMPONENTS              //
// ========================================== //

// =========DealerLocationsPopoverProps====== //

export interface DealerLocationsPopoverProps extends CustomizableComponent {
  phoneConfig?: {
    icon?: IconTypes
    format?: string
  }
}

// ================ComponentRoot============= //

export interface ComponentRootProps {
  children?: ReactNode | ReactNode[]
  className?: string
  classes?: {
    root?: string
    content?: string
  }
  nameInLayout?: string
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void
}

// =========DealerLocationsMenuProps====== //

export interface DealerLocationsMenuProps extends CustomizableComponent {
  onClick?: () => void
  isFooterContainer?: boolean
  isHeaderContainer?: boolean
  config?: TextConfig
  test: RefObject<HTMLDivElement>
  shouldUseListView?: boolean
}

// =========ExtendedIconSVGProps====== //
export interface ExtendedIconSVGProps extends IconProps, ComponentInfo {
  componentProps: {
    iconName: string
  }
}

// =========DealerLocationsMenuProps====== //

export interface DealerLocationsListProps extends CustomizableComponent {
  onClick?: () => void
  config?: TextConfig
  test: RefObject<HTMLDivElement>
}

// ==============DynamicComponent============ //

export interface DynamicComponentProps extends
  ComponentInfo, TemplateConfigs, CustomizableComponent {}

// ================FeedbackForm============= //

export interface FeedbackFormProps extends ComponentInfo {}

// =============FiltersDrawerToggler========= //

export interface FiltersDrawerTogglerProps extends ComponentInfo, CustomizableComponent {
  breakpoints?: Breakpoints
  startIcon?: SvgIconProps['type']
  text?: string
}

// ===============CarAppraisalForm=========== //
export interface CarAppraisalFormProps extends ComponentInfo, CustomizableComponent {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}

// ===================Modal================== //
export type ModalProps = UI.ModalProps & ComponentInfo & CustomizableComponent & {
  intro?: string
}

// =================ExtendedImage============ //

export interface ExtendedImageProps extends ComponentInfo {
  src?: string | null
  href?: string | null
  alt?: string | null
  isBackground?: boolean
  width?: number
  height?: number
  backgroundSize?: 'cover' | 'contain'
}

// =================LazyImage================ //
export interface LazyImageProps extends ComponentInfo {
  src?: string | null
  alt?: string | null
  lazyLoad?: boolean
  isPlaceholder?: boolean
}

// #endregion

// #region
// ========================================== //
//   SUPPORTED STATIC COMPONENTS FOR LAYOUTS  //
// ========================================== //

// ================RouteOutlet=============== //

export interface RouteOutletProps extends OutletProps, ComponentInfo, CustomizableComponent {}

// ==================Header================== //

export type HeaderComponentProps = Required<Pick<ComponentPropsMap, HeaderComponents>> & {
  Login?: LoginProps
  Login1?: LoginProps
  Login2?: LoginProps
  SubHeader: any
}

export type HeaderComponents =
  | SupportedComponents.Login
  | SupportedComponents.List
  | SupportedComponents.DrawerMenu
  | SupportedComponents.ExtendedImage
  | SupportedComponents.SearchBar
  | SupportedComponents.DealerInfoFragment
  | 'SubHeader'

export interface HeaderProps extends ComponentInfo, TemplateProps, CustomizableComponent, DealerInfoFragmentConfigProps {
  componentProps: HeaderComponentProps
  useDealFlowNavigation?: boolean
}

// ================FooterInfo================ //
export interface FooterInfoProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  footerImg?: string
}

// ==================Login=================== //
export interface LoginProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  buttonIcon?: ButtonProps['startIcon']
  buttonType?: ButtonProps['type']
  buttonVariant?: string
  onClick?: () => void
  useDealFlowNavigation?: boolean
}

// ==================GetStarted============== //
export interface GetStartedProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  dealerId: string
  vehicle?: API.VehicleItem | null
}

// ==========GetPrequalifiedComponent======== //

export interface GetPrequalifiedButtonProps extends CustomizableComponent {
  text?: 'Get pre-qualified'
  onClick?: () => void
}

export interface VehicleServiceRequestFormProps {
  dealerId: string | number
  vehicleInfo?: string
  serviceType: ServiceType
  formVariant?: string
  modalVariant?: string
  vehicleId?: number
}

// ==================Messenger============== //
export interface MessengerProps extends ComponentInfo, TemplateProps, CustomizableComponent {

}

export interface VehicleServiceRequestModalFormProps extends VehicleServiceRequestFormProps {
  handleSubmit?: () => void
  onCloseModal: () => void
  isModalOpen: boolean
}

export interface GetPrequalifiedComponentProps extends ComponentInfo, CustomizableComponent {
  className?: string
  dealerId: string | number
  buttonProps: GetPrequalifiedButtonProps
  formProps?: VehicleServiceRequestFormProps
}

export interface GetPrequalifiedRedirectComponentProps extends GetPrequalifiedComponentProps {
  to: string
}

export interface GetPrequalifiedFormProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Button: ButtonProps
    TextHeadPersonalInfo: TextProps
  }
}

export interface UnsubscribeButtonProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Button: ButtonProps
  }
}

export interface SsnYearIncomeFormProps extends ComponentInfo, CustomizableComponent {
}

export interface GetPrequalifiedSuccessProps extends ComponentInfo, CustomizableComponent {
  componentProps: {
    Text1: TextProps
    Text2: TextProps
    Text3: TextProps
    Text4: TextProps
    Text5: TextProps
    Text6: TextProps
    ExtendedIconSVG1: ExtendedIconSVGProps
    ExtendedIconSVG2: ExtendedIconSVGProps
  }
}

// ==================Carousel================ //

export interface CarouselConfigs {
  interval?: number
  pauseOnMouseEnter?: boolean
}
export interface CarouselProps extends ComponentInfo, CustomizableComponent {
  slideProps: DynamicComponentProps[]
  configs?: CarouselConfigs
}

// ===================Text=================== //

export interface TextConfig {
  variant?: TypographyVariant
  showEllipsis?: boolean
  color?: React.CSSProperties['color']
  fontWeight?: number
  fontSize?: string
  textAlign?: string
  lineHeight?: number | string
}

export interface ExtendedTypographyProps extends TextConfig, Omit<TypographyProps, 'variant' | 'color'> {
  component?: ElementType
  isHoverable?: boolean
  className?: string
  value?: string
  nameInLayout?: string
}

export interface TextLabelConfig {
  value?: TextConfig
  label?: TextConfig
}

export const isTextConfigCommon = (config?: TextConfig | TextLabelConfig): config is TextConfig => {
  if (config == null) {
    return true
  }
  const maybeTextLabelConfig = config as TextLabelConfig
  return maybeTextLabelConfig.value == null && maybeTextLabelConfig.label == null
}

export interface TextProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  value?: string | number | null
  label?: string
  config?: TextConfig | TextLabelConfig
  icon?: string
  linkPath?: string
  format?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  rootRef?: RefObject<HTMLDivElement>
}

export interface GetPrequalifiedDisclosureProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  rootRef?: RefObject<HTMLDivElement>
}

export interface TextWithFillerProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  value?: string | number | null
  label?: string
  config?: TextConfig | TextLabelConfig
  fillerConfig?: FillerProps
}

export interface TooltipTextProps extends TextProps {
  Tooltip: {
    Popover?: Omit<UI.PopoverProps, 'open' | 'anchorEl'>
    Text: TextProps
    maxWidth?: number
  }
}

// ===============DealerInfo================= //

export interface DealerInfoFragmentProps {
  className?: string
  title?: string
  phone: string | null
  addressData: {
    address: string | null
    city: string | null
    state: string | null
    zipCode: string | null
  }

  phoneConfig?: {
    icon?: SvgIconProps
    label?: string
    format?: string
  }
  addressConfig?: {
    icon?: SvgIconProps
    label?: string
  }
}

type DealerInfoFragmentConfigProps = Pick<
DealerInfoFragmentProps,
'phoneConfig' | 'addressConfig' | 'title'
>

export type DealerInfoComponentProps = Required<Pick<
ComponentPropsMap,
SupportedComponents.Schedule |
SupportedComponents.Map |
SupportedComponents.DealerLocationsList |
SupportedComponents.GetDirections
>>

export interface DealerInfoProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  componentProps: DealerInfoComponentProps
  multiDealershipHeader?: string
  singleDealershipHeader?: string
  dealershipsListMode?: 'list' | 'dropdown' | 'auto'
  shouldUseDropdownHeader?: boolean
}

// ==================DealershipLocationInfo================== //

export type DealershipLocationInfoComponentsProps = Required<Pick<
ComponentPropsMap,
SupportedComponents.Schedule |
SupportedComponents.Map
>>

export interface DealershipLocationInfoProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  componentProps: DealershipLocationInfoComponentsProps
  phoneConfig?: {
    icon?: SvgIconProps
    label?: string
    format?: string
  }
  addressConfig?: {
    icon?: SvgIconProps
    label?: string
  }
}

// ==================Filler================== //

export interface CarCategoryCardProps extends ComponentInfo, CustomizableComponent, DealerInfoFragmentConfigProps {
  image: string
  label: string
  price: string
  variant: 'default' | 'secondary'
  background?: string
}

// ==================Filler================== //

export interface FillerProps extends ComponentInfo {
  color: React.CSSProperties['color']
  borderRadius?: string
  border?: string
  minWidth?: string
}

// ============DescriptionsCarousel========== //

export interface DescriptionsCarouselItem { title: string, text: string, image: string }

export interface DescriptionsCarouselProps extends ComponentInfo, CustomizableComponent {
  items: DescriptionsCarouselItem[]
  buttonProps: ButtonProps
}

// =================CarUnderList============= //

export interface CarUnderPriceListProps extends ComponentInfo, CustomizableComponent {
  items: CarCategoryCardProps[]
}

// ==============CarBodyStylePanel=========== //

export interface CarBodyStylePanelProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  title?: string | null
  showIcons?: boolean
  variant: 'default' | 'secondary'
}

// ==============PopularCarsPanel=========== //

export interface PopularCarsPanelProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  title?: string
}

// ================DrawerMenu============= //

export interface DrawerMenuProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  componentProps: { List: ListProps, SearchBar: SearchBarProps }
  useDealFlowNavigation?: boolean
  isDealerLocationsShown?: boolean
  menuIcon?: IconTypes
}

// ===================Socials================== //

export interface SocialsProps extends ComponentInfo {
  text?: string
  links?: Array<{
    src: string
    href: string
    alt: string
  }>
}

// ===================Schedule================= //

export interface ScheduleProps extends ComponentInfo {
  hours?: BusinessHours
  title?: string
}

// =============RecommendedVehicles============ //

export interface RecommendedVehiclesProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  componentProps: {
    Text: TextProps
    Button: ButtonProps
    VehicleCard: VehicleCardProps
  }
  maxItemsNumber?: number
  type: 'default' | 'secondary'
}

// =============DealershipLocations============ //

export interface DealershipLocationsProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  phoneConfig?: {
    icon?: SvgIconProps
    label?: string
    format?: string
  }
  addressConfig?: {
    icon?: SvgIconProps
    label?: string
  }
}

// ===================InfoCard================= //

export interface InfoCardProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  icon: string
  title: string
  subtitle?: string
  text: string
}

// ===================InventoryPage================= //

export interface InventoryPageProps extends ComponentInfo, TemplateProps {
  componentProps: DynamicComponentProps
  states?: {
    filters?: string
    sorting?: string
  }
  zipCodeLocationModalBreakpoints?: Breakpoints
}

// =================VehicleCard================ //

export interface VehicleCardProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  id: string | number
  active?: boolean
  openVehicleOnClick?: boolean
  vehicle?: API.VehicleItem
  prequalify?: GetPrequalifiedResponse<number>
  emptyImage?: string
  monthlyPaymentDetails?: API.VehicleMonthlyPayment | null
  isMonthlyPaymentLoading?: boolean
}

export interface InfoLinkProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  icon?: ReactNode
  title: string
  content: string
  actionTitle: string
  onClick?: () => void
  type?: 'default' | 'secondary'
  imgSrc?: string
}

// ========InfinityVehicleCardsListProps======== //

export interface VehicleInfiniteScrollProps extends ComponentInfo, TemplateProps {
  componentProps: { VehicleCard: VehicleCardProps, NoVehiclesView: NoVehiclesViewProps }
  states: {
    filters: string
    sorting: string
  }
}

// ===============NoVehiclesView============= //

export interface NoVehiclesViewProps {
  imgSrc: string
  text?: string
}

// ===============VehiclesFilter=============== //

export enum FiltersPanelComponents {
  ZipCodeInput = 'ZipCodeInput',
  VehiclesSorting = 'VehiclesSorting',
  FilterPanel = 'FilterPanel'
}

export interface VehiclesFilterProps extends ComponentInfo, CustomizableComponent {
  title?: string
  drawerBreakpoints: Breakpoints
  sections: Array<{
    label?: string
    componentName: FiltersPanelComponents
    props?: {
      variant?: string
    }
  }>
  states: {
    filters: string
    sorting: string
  }
}

// ===============ZipCodeLocation============== //

export interface ZipCodeLocationProps extends TemplateConfigs, CustomizableComponent {
  breakpoints?: Breakpoints
}

// ============ZipCodeLocationModal============ //

export interface ZipCodeLocationModalProps extends TemplateConfigs, CustomizableComponent {
  isOpen?: boolean
  breakpoints?: Breakpoints
  onClose?: () => void
}

// =============VehiclesFilterChips============= //

export interface VehiclesFilterChipsProps extends ComponentInfo {
  states: {
    filters: string
  }
}

export interface VehiclesSearchProps extends TemplateConfigs, CustomizableComponent {
  placeholder?: string
  states: {
    filters: string
  }
}

// ===============VehiclesSortingProps=============== //

export interface VehiclesSortingProps extends ComponentInfo, CustomizableComponent {
  states: {
    sorting: string
  }
  popoverClassName?: string
  startAdornmentIcon?: SvgIconProps['type']
  label?: string
  mode?: 'select' | 'search'
}

// =====================List==================== //

/**
 * As List renders Dynamics it is obligatory for a list item to have nameInTheLayout
 * so that the inner div would have full width and height
 *
 * DD-TODO: think how to improve this, because initially List did not need to have nameInTheLayout at all
 */

export interface ListProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  /**
   * by default it would be 'id'
   * then it would move to listeItem nameInTheLayout
   * and after that to index
   */
  itemKey?: string
  /**
   * specifies item renderer. DynamicComponent is by default
   */
  itemType?: string

  items?: Array<Partial<ComponentPropsMap> & {type?: SupportedComponents}>
  /**
   * lists very often are populated after fetch, so we need soma shared config for a listItem
   */
  itemComponentProps?: Partial<ComponentPropsMap>
  listName?: string
  listNameConfig?: TextConfig
}

// ===================SearchBar================ //

/**
 * DD-TODO: fix SearchBarProps type.
 * We can not pass in config any actions. But SearchBar by itself needs these functions to pass further.
 *
 * That is why onClick and onChange are optional for now
 */
export interface SearchBarComponentProps {
  [SupportedComponents.Button]: ButtonProps
  [SupportedComponents.Input]: InputProps
}

export interface SearchBarProps extends Omit<ComponentInfo, 'states'>, TemplateProps, CustomizableComponent {
  componentProps: SearchBarComponentProps
  onSearch?: (val: string) => Promise<void>
  btnText?: string
  showSearchIcon?: boolean
  label?: string
  placeholder?: string
  shouldResetOnSearch?: boolean
  shouldShowClearButton?: boolean
}

export interface SearchBarRoundedProps extends ComponentInfo {
  onSearch?: (val: string) => Promise<void>
  label?: string
  placeholder?: string
  shouldResetOnSearch?: boolean
  shouldShowClearButton?: boolean
}

// ===============MainSearchSection============= //

export interface MainSearchSectionProps extends ComponentInfo, TemplateProps, CustomizableComponent {
  searchType: 'default' | 'rounded'
  componentProps: {
    SearchBar: SearchBarProps
    SearchBarRounded?: SearchBarRoundedProps
    Text1: TextProps
    Text2: TextProps
  }
}

// ====================Button================== //

export interface ButtonProps extends ComponentInfo, CustomizableComponent {
  text: string
  onClick?: (evt?: MouseEvent) => void // DD-NOTE: temporary optional
  dataTestId?: string
  disabled?: boolean
  isLoading?: boolean
  buttonVariant?: string
  buttonClassName?: string
  type?: 'button' | 'reset' | 'submit' | 'icon'
  navTo?: string
  navAction?: 'replace' | 'push'
  startIcon?: string
  endIcon?: string
}

// ====================Input=================== //

export interface InputProps extends ComponentInfo, CustomizableComponent, Omit<UI.FormCompatible<UI.InputProps, UI.InputValue>, 'id' | 'value'> {
  /**
   * we also set input's name by its id
   */
  id?: string
  value?: string | number
}

// =====================Map==================== //

export interface MapProps extends ComponentInfo {
  addressData: {
    address: string | null
    city: string | null
    state: string | null
    zipCode: string | null
  }
  destination?: string | null
}
// =====================VehicleViewContent==================== //
export interface VehicleViewContentComponentsProps {
  GalleryVehicleView: ComponentPropsMap[SupportedComponents.GalleryVehicleView]
  VehicleBriefInfo: ComponentPropsMap[SupportedComponents.VehicleBriefInfo]
  VehicleSpecsOverview: ComponentPropsMap[SupportedComponents.VehicleSpecsOverview]
  IncludedWithCarAds: TemplateConfigs
  Text: ComponentPropsMap[SupportedComponents.Text]
  FeatureOptionList: ComponentPropsMap[SupportedComponents.FeatureOptionList]
  Text1: ComponentPropsMap[SupportedComponents.Text]
  InfoLinkBlock: { nameInLayout: string }
  TestDriveBlock: { nameInLayout: string }
}

export interface VehicleViewContentProps extends ComponentInfo, TemplateProps, CustomizableComponent, TemplateConfigs {
  id: string | number
  componentProps: VehicleViewContentComponentsProps
  noPhotoImage?: string
  infoLinkType?: 'default' | 'secondary'
  testDriveImgSrc?: string
  testDriveBtnText?: string
}

export enum VehicleMediaTypeEnum {
  Image = 1,
  Video = 2
}

export interface GalleryMediaProps {
  url: string
  thumbnail?: string
  vehicleMediaTypeId: VehicleMediaTypeEnum
}

export interface GalleryVehicleViewProps extends TemplateConfigs, CustomizableComponent {
  galleryMedia: GalleryMediaProps[]
  media: API.VehicleMediaData | null
  emptyListImage?: string
  altTextForMainImage?: string
  noPhotoClassName?: string
}

// =====================VehicleBriefInfo==================== //
export interface VehicleBriefInfoComponentsProps {
  ExploreFinancingButton?: ComponentPropsMap[SupportedComponents.Button]
}

export interface VehicleBriefInfoProps extends ComponentInfo, CustomizableComponent {
  vehicle?: API.VehicleItem | null
  carfaxPartnerCode: string
  componentProps?: VehicleBriefInfoComponentsProps
  onClick: () => void
  dealerId?: string | null
  type?: 'default' | 'with-location'
  dealership?: API.DealerInfo | null
}

export interface CarAdTextFieldsProps {
  Text1: string
  Text2: string
}

export interface IncludedWithCarAdsProps extends ComponentInfo, TemplateConfigs, CustomizableComponent {
  settings?: API.Settings | null
  carAdTextFieldsProps?: CarAdTextFieldsProps | null
}

export interface IncludedWithCarAdsMapperProps {
  key: string
  component?: string
  settings?: API.Settings | null
  propsMap?: Partial<ComponentPropsMap>
  carAdTextFieldsProps?: CarAdTextFieldsProps | null
}

// =====================VehicleDescription==================== //

export interface VehicleDescriptionProps extends ComponentInfo, CustomizableComponent {
  description: string | null
}

// =====================VehicleDealershipInfo==================== //

export interface VehicleDealershipInfoProps extends ComponentInfo {
  dealership: API.DealerInfo | null
}

// =====================VehicleSpecsOverview==================== //
export interface VehicleSpecsOverviewComponentsProps {
  Text: ComponentPropsMap[SupportedComponents.Text]
  Title: ComponentPropsMap[SupportedComponents.Text]
  VinStickerButton?: ComponentPropsMap[SupportedComponents.VinStickerButton]
}

export interface VehicleSpecsOverviewProps extends TemplateProps, ComponentInfo, CustomizableComponent {
  componentProps: VehicleSpecsOverviewComponentsProps
  vehicle?: API.VehicleItem | null
  odometerIcon: string
  exteriorColorIcon: string
  interiorColorIcon: string
  engineIcon: string
}

export interface FeatureOptionItem {
  name: string
  options: string[]
}

export interface FeatureOptionListProps extends TemplateProps, ComponentInfo, CustomizableComponent {
  height?: number
  options?: FeatureOptionItem[]
  className?: string
}

// ==========================RequestHelpOrDrive========================= //
export interface RequestHelpOrDriveProps extends
  ComponentInfo, CustomizableComponent, VehicleServiceRequestFormProps {}

// ================================Banner=============================== //
export interface BannerProps extends ComponentInfo {
  Text: {
    value: string
    config?: TextConfig
  }
  Image: ComponentPropsMap[SupportedComponents.ExtendedImage]
  Filler?: ComponentPropsMap[SupportedComponents.Filler]
}

// ===========================CustomerReviews=========================== //
export interface CustomerReviewsProps extends ComponentInfo {
  Text: {
    value: string
    config?: TextConfig
  }
}

// #endregion

// ==========================VinStickerButton=========================== //
export interface VinStickerButtonProps extends ComponentInfo {
  vin: string
  text?: string
}

// #endregion

// ===========================TradeIn=========================== //
export interface TradeInDetailsFormPageProps extends ComponentInfo {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
  }
}
export interface TradeInCarProps extends ComponentInfo, CustomizableComponent {
  isTitleExist?: boolean
  isMobileTitleHidden?: boolean
  isPriceBarExist?: boolean
  isActionBarExist?: boolean
  isSearchCarExist?: boolean
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
    dealership: string
  }
}

export interface TradeInUserDetailsFormProps extends ComponentInfo, CustomizableComponent {
  states: {
    tradeInVehicle: string
    tradeInDetailsVehicle: string
    dealership: string
  }
}

export interface ConditionSubOptionProps {
  title: string
  value: number
  isChecked?: boolean
}

export interface ConditionOptionProps {
  className?: string
  title: string
  value: number
  subOptions?: ConditionSubOptionProps[]
  isActive?: boolean
  onClick?: (id: number, options?: ConditionSubOptionProps[]) => void
  description?: string
}

export interface ConditionData {
  value: number | null
  subOptionValue: number[] | null
}

export interface ConditionProps extends ComponentInfo, CustomizableComponent {
  subOptionsType?: 'checkbox' | 'radio'
  id: string
  title: string
  options: ConditionOptionProps[]
  onChange?: (id: string, value: ConditionData) => void
  value?: ConditionData | null
  error?: unknown | string | null
  touched?: unknown | boolean | null
}

// ===================== GetDirections ==================== //
export interface GetDirectionsProps extends TemplateProps, ButtonProps {
  addressData: MapProps['addressData']
  selector?: string
}

// HelmetProps

interface MetaTag {
  name: string
  content: string
}

interface OpenGraphTag {
  property: string
  content: string
};

export interface HelmetProps extends Omit<ComponentInfo, 'states'>, TemplateProps, CustomizableComponent {
  title: string
  meta: MetaTag[]
  ogTags?: OpenGraphTag[]
}

export interface HeadTitleProps extends Omit<ComponentInfo, 'states'>, TemplateProps, CustomizableComponent {
  title: string
}

// =========================== LocalBusiness =========================== //

export interface LocalBusinessItemProps {
  name: string
  url: string
  logo: string | null
  telephone: string
  email: string | null
  address: {
    streetAddress: string
    addressLocality: string
    addressRegion: string
    postalCode: string
    addressCountry: string
  }
  images?: string[]
  dealerBusinessHours?: BusinessHours
}

export interface LocalBusinessComponentProps extends LocalBusinessItemProps {
  department?: LocalBusinessItemProps[]
}

export interface LocalBusinessProps extends Omit<ComponentInfo, 'states'>, TemplateProps, CustomizableComponent {
  schemaProps?: LocalBusinessComponentProps | null
}

// #endregion
