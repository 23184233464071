import { type AxiosResponse } from 'axios'
import {
  type SendVerificationCodePayload,
  type RegistrationPayload,
  type TokenResponse,
  type LoginPayload,
  type SendResetEmail,
  type ResetPassword
} from 'api/types/user.types'
import { WrapperRequest } from '../wrapper.api'
import { buildLoginData } from 'constants/store_helpers'
import transformToRecord from 'website/utils/transformToRecord'
import type { CreateBotPayload } from 'website/api/types/crm'
import { type GetPrequalified, type GetPrequalifiedResponse } from '../types/financing'
import { type TradeInVehiclePricePayload } from '../types/tradeIn'
import { type Lead } from '../types/leads'
import { type ApiUrls, type DealerInfoPayload } from './types'
import API_URLS from './urls'

class HeadquartersApiProvider extends WrapperRequest {
  private readonly urls: ApiUrls = API_URLS

  async getDealerInfo (id: number | string, payload?: DealerInfoPayload): Promise<API.DealerInfo[]> {
    return await this.post(`/api/v1/Dealers/public-info?headquartersId=${id}`, payload)
  }

  async getDealerSettings (headquartersId: number | string): Promise<API.Settings> {
    return await this.get(`api/v1/Settings/headquarters/${headquartersId}/website`)
  }

  async getPrequalified (payload: GetPrequalified, id: string): Promise<GetPrequalifiedResponse> {
    return await this.post(`/api/v1/Headquarters/${id}/prequalify`, payload)
  }

  async getTradeInPrice (payload: TradeInVehiclePricePayload, id: string): Promise<number | null> {
    return await this.post(`/api/v1/Headquarters/${id}/tradein/price`, payload)
  }

  async getRecommendedVehicles (headquartersId: string, vehicleId?: string): Promise<{items: API.VehicleItem[]}> {
    return await this.get(`${this.urls.inventory}/api/v1/Vehicles/recommended-vehicles?headquartersId=${headquartersId}${vehicleId != null ? `&vehicleId=${vehicleId}` : ''}`)
  }

  async calculateFilters (payload: API.FiltersRequest, id: string): Promise<API.FiltersResponse> {
    return await this.post(`${this.urls.inventory}/api/v1/Headquarters/${id}/search/facets`, payload)
  }

  async getVehiclesList (payload: API.SearchVehiclesRequest, id: string): Promise<API.SearchVehiclesResponse> {
    return await this.post(`${this.urls.inventory}/api/v1/Headquarters/${id}/vehicles/elastic-search`, payload)
  }

  async getWindowSticker (vin: string, headquartersId: string): Promise<API.VehicleVinWindowSticker> {
    return await this.get(`${this.urls.inventory}/api/v1/Headquarters/${headquartersId}/vehicles/${vin}/window-sticker`)
  }

  async createLead (payload: Lead, headquartersId: string): Promise<void> {
    return await this.post(
      `${this.urls.crm}/api/v1/Leads/web`,
      { ...payload, headquartersId }
    )
  }

  async createBot ({ locationLatitude, locationLongitude, headquartersId }: CreateBotPayload): Promise<API.CreateBotDto> {
    return await this.post(`${this.urls.crm}/api/v1/Bot/thread`, {
      locationLatitude,
      locationLongitude,
      headquartersId
    })
  }

  async createBotMessage (threadId: string, content: string, vehicleId?: string): Promise<API.CreateBotMessageDto> {
    return await this.post(
      `${this.urls.crm}/api/v1/Bot/message`,
      { threadId, content, vehicleId }
    )
  }

  async getDefaultBotQuestions (dealerId: string | number): Promise<API.BotDefaultQuestionsDto> {
    return await this.get(
      `${this.urls.crm}/api/v1/Bot/${dealerId}/default-questions`
    )
  }

  async loginUser (values: LoginPayload, headquartersId: string): Promise<TokenResponse> {
    const loginData = transformToRecord({ ...values, headquartersId })

    const data = (values.username != null && values.password != null)
      ? buildLoginData('password', loginData)
      : buildLoginData('verification_code', loginData)

    return await this.post(`${this.urls.identity}/connect/token`, {
      data,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      headquartersId
    })
  }

  async registerUser (payload: RegistrationPayload, headquartersId: string): Promise<AxiosResponse> {
    return await this.post(
      `${this.urls.identity}/api/v1/account`,
      { ...payload, headquartersId }
    )
  }

  async sendVerificationCode (payload: SendVerificationCodePayload, headquartersId: string): Promise<void> {
    return await this.post(
      `${this.urls.identity}/api/v1/account/sendVerificationCode`,
      { ...payload, headquartersId }
    )
  }

  async sendResetPasswordEmail (payload: SendResetEmail, headquartersId: string): Promise<void> {
    return await this.post(
    `${this.urls.identity}/api/v1/account/sendReset`,
    { ...payload, headquartersId }
    )
  }

  async resetPassword (payload: ResetPassword, headquartersId: string): Promise<void> {
    return await this.post(
    `${this.urls.identity}/api/v1/account/resetPassword`,
    { ...payload, headquartersId }
    )
  }

  async unsubscribeLead (unsubscribeParams: string): Promise<void> {
    return await this.patch(
      `${this.urls.crm}/api/v1/Leads/unsubscribe${unsubscribeParams}`
    )
  }
}

export default new HeadquartersApiProvider()
