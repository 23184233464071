import { type FC } from 'react'
import { cnx, Button } from '@carfluent/common'

import IconSVG from 'website/components/icons'
import Textarea from 'website/components/Textarea'
import ReCaptcha from 'components/common/ReCaptcha'
import EmojiPicker from 'website/components/EmojiPicker'

import translate from 'website/utils/translate'
import { getTextareaValuesWithEmoji } from 'website/components/EmojiPicker/utils'

import RequestCall from '../RequestCall'
import TypingIndicator from '../TypingIndicator'
import Message, { MessageProps } from '../Message'

import { useChat } from './hook'
import CLASS_NAME from './styles'

export interface ChatProps {
  className?: string
  onClose: () => void
  threadId: string | null
  isOpened: boolean
  defaultMessages: MessageProps[] | null
  onLastMessage: (message: MessageProps | null) => void
  defaultQuestions: API.BotDefaultQuestionDto[]
}

const Chat: FC<ChatProps> = ({
  className,
  onClose,
  threadId,
  isOpened,
  defaultMessages,
  onLastMessage,
  defaultQuestions
}) => {
  const {
    setIsRequestCallOpened,
    isRequestCallOpened,
    isSending,
    isDisabledSend,
    isFirstTyping,
    messages,
    value,
    setValue,
    onKeyDown,
    onSend,
    onSubmitRequestCall,
    refChatContent,
    refChatContainer,
    refRecaptcha,
    sendMessage
  } = useChat({ threadId, isOpened, defaultMessages, onLastMessage })

  const isPreviewContent = messages.length === 1

  return (
    <div className={cnx(CLASS_NAME, className)} ref={refChatContainer}>
      <div onClick={onClose} className='chat-header'>
        {translate('chat.windowTitle')}

        <IconSVG.Close width={24} height={24} fill='white' />
      </div>

      <Button
        variant='outlined'
        onClick={() => setIsRequestCallOpened(true)}
        className='request-call-button'
      >
        {translate('chat.callButtonTitle')}
      </Button>

      <div className='chat-content' ref={refChatContent}>
        {(isSending || isFirstTyping) && <TypingIndicator />}

        {isPreviewContent && (
          <>
            {defaultQuestions.length > 0 && (
              <div className='chat-preview-content'>
                {defaultQuestions.map((item, index) => {
                  return (
                    <div
                      onClick={() => sendMessage(item.question, item.includeVehicleIdMetadata)}
                      key={index} className='chat-preview-item'
                    >
                      {item.question} <IconSVG.ArrowRight />
                    </div>
                  )
                })}
              </div>
            )}

            <p className='chat-preview-first-message'>
              How can I assist you?
            </p>
          </>
        )}

        {messages.length > 1 && messages.map((message) => {
          return (
            <Message
              key={message.id}
              {...message}
            />
          )
        })}
      </div>

      <div className='chat-textarea-wrapper'>
        <Textarea
          value={value}
          onChange={(_, value) => setValue(value)}
          placeholder='Type your message'
          onKeyDown={onKeyDown}
        />

        <EmojiPicker
          onEmojiSelect={(emoji) => {
            const textarea: HTMLTextAreaElement | null = document.querySelector('.chat-textarea-wrapper textarea')
            if (textarea != null) {
              setValue(getTextareaValuesWithEmoji(textarea, value, emoji))
            }
          }}
          rootClassName='emoji-picker'
        />

        <div className={cnx('chat-send-icon', isDisabledSend && 'disabled')} onClick={onSend}>
          <IconSVG.Send />
        </div>
      </div>

      <RequestCall
        isOpened={isRequestCallOpened}
        onClose={() => setIsRequestCallOpened(false)}
        onSubmit={onSubmitRequestCall}
      />

      <ReCaptcha
        badge='bottomleft'
        ref={refRecaptcha}
        size='invisible'
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA ?? ''}
      />
    </div>
  )
}

export default Chat
