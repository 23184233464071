import { formatCurrency, formatInteger, Button } from '@carfluent/common'
import type { FC } from 'react'

import InfoIcon from 'website/components/icons/info'
import GetStartedButton from 'website/components/GetStartedButton'

import askIcon from 'website/assets/ask_icon.svg'

import type { CalculatedPrequalify } from 'website/components/VehicleCard/parser'

export interface DefaultContentProps {
  vehicle: API.VehicleItem
  price: string
  paymentInfo: CalculatedPrequalify | null
  onClick: () => void
  tooltip?: string
}

const DefaultContent: FC<DefaultContentProps> = ({
  vehicle,
  price,
  paymentInfo,
  onClick,
  tooltip
}) => {
  return (
    <>
      <p className='brief-info-name'>
        {
          (vehicle.year != null && vehicle.make != null && vehicle.model != null)
            ? `${vehicle.year ?? ''} ${vehicle.make ?? ''} ${vehicle.model ?? ''}`
            : '-'
        } <br />
        <span>{vehicle.trim != null ? vehicle.trim ?? '' : '-'}</span>
      </p>

      <div className='brief-info-price-mileage-wrapper'>
        <p className='brief-info-sale-price'>
          {price}
        </p>

        <p className='brief-info-mileage'>
          {formatInteger(vehicle.mileage, { emptyValues: '0' })} miles
        </p>
      </div>

      {paymentInfo != null && (
        <div className='payment-block-wrapper'>
          <div className='payment-block-title'>
            Monthly payment

            <span className='info-wrapper'>
              <InfoIcon />

              {tooltip != null && (
                <div className='payment-tooltip'>
                  {tooltip}
                </div>
              )}
            </span>
          </div>

          <div className='payment-block-info'>
            <p>
              Est. {formatCurrency(paymentInfo.monthlyPayment)}/mo
            </p>

            <p className='cash-down'>
              Cash down {formatCurrency(paymentInfo.cashDown)}
            </p>
          </div>
        </div>
      )}

      <div className='brief-info-vin-stock-wrapper'>
        <p className='brief-info-vin'>
          VIN: {vehicle.vin}
        </p>

        <p className='brief-info-stock'>
          Stock: {vehicle.stock ?? '-'}
        </p>
      </div>

      <GetStartedButton
        nameInLayout='GetStartedButton'
        dealerId={vehicle.dealerId}
        vehicle={vehicle}
      />

      <div
        className='cn-button-container'
        data-vin={vehicle.vin}
        data-page-type='vdp'
        data-type='used'
      />

      <Button
        onClick={onClick}
        className='ask-button'
        variant='text'
      >
        <img src={askIcon} alt='ask icon' />

        HAVE QUESTIONS? ASK US!
      </Button>
    </>
  )
}

export default DefaultContent
