import car from 'website/assets/car.png'
import odometerIcon from 'website/assets/odometer.svg'
import exteriorColorIcon from 'website/assets/exterior_color.svg'
import interiorColorIcon from 'website/assets/interior_color.svg'
import engineIcon from 'website/assets/engine.svg'
import emptyImage from 'website/assets/empty_card.svg'
import testDriveIcon from 'website/assets/test_drive.svg'

const assets: Record<string, string> = {
  car,
  odometerIcon,
  exteriorColorIcon,
  interiorColorIcon,
  engineIcon,
  emptyImage,
  testDriveIcon,
  includedWithCarAdsTemplate: '',
  carInfo: ''
}

export default assets
