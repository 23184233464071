import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const CheckIcon: FC<IconProps> = ({ stroke }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path d='M4.72648 8.68235C4.41563 8.36497 4.41566 7.85728 4.72654 7.53994C5.04663 7.2132 5.57268 7.2132 5.89276 7.53994L7.21408 8.88871L10.4412 5.59511C10.7613 5.26843 11.2873 5.26845 11.6074 5.59517C11.9183 5.91253 11.9183 6.42027 11.6074 6.73764L7.84912 10.574C7.50054 10.9298 6.92765 10.9298 6.57911 10.5739L4.72648 8.68235Z' fill='var(--mainColor)' />
    </svg>
  )
}

export default CheckIcon
