import { css } from '@emotion/css'

export default css`
  display: flex;
  flex-direction: column;

  .vehicle-name {
    color: #222429;
    font-family: "Plus Jakarta Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 120%; 
    margin-bottom: 8px;
  }

  .vehicle-sub-title {
    color: rgba(34, 36, 41, 0.80);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
  }

  .vehicle-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    .location-wrapper {
      border-radius: 8px;
      background: rgba(65, 110, 235, 0.08);
      color: #222429;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 12px;
      flex: 1;
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--mainColor);
        margin-right: 4px;
      }
    }

    .direction-icon {
      padding: 0;
      border-radius: 8px;
      border: 1px solid #DCDEE0;
      background: #FFF;
      width: 40px;
      height: 40px;
    }
  }

  .vehicle-price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      color: #222429;
      font-family: "Plus Jakarta Sans";
      font-size: 32px;
      font-weight: 600;
    }

    .monthly-payment-block {
      display: flex;
      flex-direction: column;
      gap: 4px;


      .per-month {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        color: rgba(34, 36, 41, 0.80);
        position: relative;

        :hover > .prequalify-tooltip {
          display: block;
        }
        
        .prequalify-tooltip {
          :hover {
            display: block;
          }
          
          text-align: left;
          display: none;
          position: absolute;
          max-width: 266px;
          padding: 4px 12px;
          border-radius: 4px;
          background: #212121;
          top: 0;
          right: 0;
          color: white;
          transform: translate(0px, -100%);
          width: max-content;
          font-size: 14px;
        }
      }

      .cash-down {
        color: rgba(34, 36, 41, 0.56);
        font-family: Inter;
        font-size: 14px;
        line-height: 140%; /* 19.6px */
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #EBECF0;
    margin: 16px 0;
  }

  .vehicle-vin-stock-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px; 

    > .brief-info-item {
      color: rgba(34, 36, 41, 0.56);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #222429;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .cf-get-started-button {
    margin-bottom: 12px;

    :hover {
      background: linear-gradient(0deg, rgba(247, 248, 250, 0.08) 0%, rgba(247, 248, 250, 0.08) 100%), #416EEB;
    }

    .cf-button-content {
      text-transform: capitalize;
    }
  }

  .GetPrequalifiedComponent {
    margin-bottom: 12px;

    .GetPrequalifiedButton {
      max-width: none;
      
      .MuiButton-root {
        height: 56px;
        max-height: 56px;
      }
      .MuiButtonBase-root {
        background-color: #222429;

        :hover {
          background: linear-gradient(0deg, rgba(247, 248, 250, 0.08) 0%, rgba(247, 248, 250, 0.08) 100%), #222429;
        }
      }

      .MuiButton-label {
        text-transform: capitalize;
      }
    }
  }

  .question-button {
    &.cf-button-root.cf-button-variant-outlined:hover {
      background-color: #F7F8FA;
    }

    .cf-button-content {
      color: #222429;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
    }
  }


  @media (min-width: 768px) {
    .vehicle-name {
      font-size: 24px;
    }

    .vehicle-sub-title {
      font-size: 18px;
    }
  }

`
