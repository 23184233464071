import { type CreateBotPayload } from 'website/api/types/crm'
import { type RecaptchaDto } from 'api/types/requests'

import { WrapperRequest } from '../wrapper.api'
import { type GetPrequalified, type GetPrequalifiedResponse } from '../types/financing'
import { type TradeInVehiclePricePayload } from '../types/tradeIn'
import { FeedbackFormDto, type Lead } from '../types/leads'
import { type ApiUrls } from './types'
import API_URLS from './urls'

class DefaultApiProvider extends WrapperRequest {
  private readonly urls: ApiUrls = API_URLS

  async getDealerInfo (id: number | string): Promise<API.DealerInfo[]> {
    const res = await this.get(`/api/v1/Dealers/${id}/public-info`)

    /**
     * Returning array for compatibility with HeadquartersApiProvider
     */
    return [res]
  }

  async getDealerSettings (dealerId: number | string): Promise<API.Settings> {
    return await this.get(`api/v1/Settings/${dealerId}/website`)
  }

  async getPrequalified (payload: GetPrequalified, id: string): Promise<GetPrequalifiedResponse> {
    return await this.post(`/api/v1/Dealers/${id}/prequalify`, payload)
  }

  async getTradeInPrice (payload: TradeInVehiclePricePayload, id: string): Promise<number | null> {
    return await this.post(`/api/v1/Dealers/${id}/tradein/price`, payload)
  }

  async getRecommendedVehicles (dealerId: string, vehicleId?: string): Promise<{items: API.VehicleItem[]}> {
    return await this.get(`${this.urls.inventory}/api/v1/Dealers/${dealerId}/recommended-vehicles${vehicleId != null ? `?vehicleId=${vehicleId}` : ''}`)
  }

  async calculateFilters (payload: API.FiltersRequest, id: string): Promise<API.FiltersResponse> {
    return await this.post(`${this.urls.inventory}/api/v1/Dealers/${id}/search/facets`, payload)
  }

  async getVehiclesList (payload: API.SearchVehiclesRequest, id: string): Promise<API.SearchVehiclesResponse> {
    return await this.post(`${this.urls.inventory}/api/v1/Dealers/${id}/vehicles/elastic-search`, payload)
  }

  async getWindowSticker (vin: string, dealerId: string): Promise<API.VehicleVinWindowSticker> {
    return await this.get(`${this.urls.inventory}/api/v1/Dealers/${dealerId}/vehicles/${vin}/window-sticker`)
  }

  async createLead (payload: Lead, dealerId: string): Promise<void> {
    return await this.post(
      `${this.urls.crm}/api/v1/Leads/web`,
      { ...payload, dealerId: payload.dealerId ?? dealerId }
    )
  }

  async createBot ({ dealerId }: CreateBotPayload): Promise<API.CreateBotDto> {
    return await this.post(`${this.urls.crm}/api/v1/Bot/thread`, { dealerId })
  }

  async getBotSettings (dealerId: string): Promise<API.BotSettings> {
    return await this.get(`${this.urls.crm}/api/v1/Settings/${dealerId}/bot`)
  }

  async createBotMessage (threadId: string, content: string, vehicleId?: string): Promise<API.CreateBotMessageDto> {
    return await this.post(
      `${this.urls.crm}/api/v1/Bot/message`,
      { threadId, content, vehicleId }
    )
  }

  async getDefaultBotQuestions (dealerId: string | number): Promise<API.BotDefaultQuestionsDto> {
    return await this.get(
      `${this.urls.crm}/api/v1/Bot/${dealerId}/default-questions`
    )
  }

  async getBotChatByThreadId (threadId: string): Promise<API.WebChatDto> {
    return await this.get(`${this.urls.crm}/api/v1/Bot/${threadId}`)
  }

  async sendLeadFeedback (feedbackId: string, payload: FeedbackFormDto, leadId?: string): Promise<void> {
    return await this.patch(
      `${this.urls.crm}/api/v1/LeadFeedback/${feedbackId}${leadId == null ? '' : `?leadId=${leadId}`}`,
      payload
    )
  }

  async unsubscribeLead (unsubscribeParams: string): Promise<void> {
    return await this.patch(
      `${this.urls.crm}/api/v1/Leads/unsubscribe${unsubscribeParams}`
    )
  }

  async verifyRecaptcha (payload: RecaptchaDto): Promise<{ success: true }> {
    return await this.post(`${this.urls.identity}/api/v1/Recaptcha/verify`, payload)
  }
}

export default new DefaultApiProvider()
