import { type FC, useMemo } from 'react'
import { cnx, formatCurrency } from '@carfluent/common'

import type { VehicleBriefInfoProps } from 'website/components/types'
import { useComponentStyles } from 'website/styles/useComponentStyles'
import { getTooltip, calculatePrequalify } from 'website/components/VehicleCard/parser'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'
import { useMonthlyPayments } from 'website/hooks/useMonthlyPayments'

import DefaultContent from './components/DefaultContent'
import WithLocationContent from './components/WithLocationContent'

const useSharedState = SharedStateHook<Store.PrequalifyState>(StoreBranches.Prequalify)
const DEFAULT_VEHICLES: API.VehicleItem[] = []

const VehicleBriefInfo: FC<VehicleBriefInfoProps> = (props) => {
  const {
    nameInLayout = 'VehicleBriefInfo',
    className,
    variant,
    vehicle,
    onClick,
    type = 'default',
    dealership
  } = props
  const componentStylesCls = useComponentStyles(nameInLayout, variant)
  const [prequalify] = useSharedState(defaultInstance(StoreBranches.Prequalify))

  const vehicles = useMemo(() => {
    return vehicle != null ? [vehicle] : DEFAULT_VEHICLES
  }, [vehicle])

  const { monthlyPayments } = useMonthlyPayments({ vehicles })
  const monthlyPaymentDetails = vehicle?.id != null ? monthlyPayments[vehicle.id] : null

  if (vehicle == null || vehicle.dealerId == null || vehicle.vin == null) {
    return null
  }

  const parsedPrequalify = calculatePrequalify({
    ...prequalify,
    price: vehicle.salePrice ?? 0
  })

  const isPrequalify = parsedPrequalify != null

  const paymentInfo = parsedPrequalify ?? (
    monthlyPaymentDetails != null
      ? { monthlyPayment: monthlyPaymentDetails.monthlyPayment, cashDown: monthlyPaymentDetails.cashDown }
      : null
  )

  const tooltip = getTooltip({
    term: (isPrequalify ? prequalify.term : monthlyPaymentDetails?.term) ?? null,
    apr: (isPrequalify ? prequalify?.apr : monthlyPaymentDetails?.apr) ?? 0
  })

  const price = (vehicle.salePrice != null && vehicle.salePrice >= 1000)
    ? formatCurrency(vehicle.salePrice)
    : 'Call'

  return (
    <div className={cnx(nameInLayout, componentStylesCls.root, className, type)}>
      {type === 'default' && (
        <DefaultContent
          vehicle={vehicle}
          price={price}
          paymentInfo={paymentInfo}
          onClick={onClick}
          tooltip={tooltip}
        />
      )}

      {type === 'with-location' && (
        <WithLocationContent
          vehicle={vehicle}
          price={price}
          paymentInfo={paymentInfo}
          onClick={onClick}
          tooltip={tooltip}
          dealership={dealership}
        />
      )}
    </div>
  )
}

export default VehicleBriefInfo
