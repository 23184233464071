import type { FC } from 'react'
import { IconProps } from 'website/components/types'

const PlaceIcon: FC<IconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <g clip-path='url(#clip0_10659_4117)'>
        <path d='M9.9987 9.99935C9.08203 9.99935 8.33203 9.24935 8.33203 8.33268C8.33203 7.41602 9.08203 6.66602 9.9987 6.66602C10.9154 6.66602 11.6654 7.41602 11.6654 8.33268C11.6654 9.24935 10.9154 9.99935 9.9987 9.99935ZM14.9987 8.49935C14.9987 5.47435 12.7904 3.33268 9.9987 3.33268C7.20703 3.33268 4.9987 5.47435 4.9987 8.49935C4.9987 10.4493 6.6237 13.0327 9.9987 16.116C13.3737 13.0327 14.9987 10.4493 14.9987 8.49935ZM9.9987 1.66602C13.4987 1.66602 16.6654 4.34935 16.6654 8.49935C16.6654 11.266 14.4404 14.541 9.9987 18.3327C5.55703 14.541 3.33203 11.266 3.33203 8.49935C3.33203 4.34935 6.4987 1.66602 9.9987 1.66602Z' fill='#416EEB' />
      </g>
      <defs>
        <clipPath id='clip0_10659_4117'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlaceIcon
