import type { FC } from 'react'
import { Button, formatCurrency, IconButton } from '@carfluent/common'

import GetPrequalifiedComponent from 'website/components/GetPrequalifiedComponent'
import GetStartedButton from 'website/components/GetStartedButton'
import IconSVG from 'website/components/icons'

import type { CalculatedPrequalify } from 'website/components/VehicleCard/parser'

import CLASS_NAME from './styles'
import { fullAddressShort } from 'website/utils/googleMap'
import { createGoogleMapsLink } from 'website/utils/links'

export interface DefaultContentProps {
  vehicle: API.VehicleItem
  price: string
  paymentInfo: CalculatedPrequalify | null
  onClick: () => void
  tooltip?: string
  dealership?: API.DealerInfo | null
}

const WithLocationContent: FC<DefaultContentProps> = ({
  vehicle,
  price,
  dealership,
  paymentInfo,
  onClick,
  tooltip
}) => {
  const onRedirectGoogleMaps = (): void => {
    if (dealership == null) {
      return
    }

    const address = fullAddressShort({
      address: dealership.address1,
      city: dealership.city,
      state: dealership.state,
      zipCode: dealership.zipCode
    })

    if (address == null) {
      return
    }

    window.open(createGoogleMapsLink(address), '_blank')
  }

  return (
    <div className={CLASS_NAME}>
      <p className='vehicle-name'>{
        (vehicle.year != null && vehicle.make != null && vehicle.model != null)
          ? `${vehicle.year ?? ''} ${vehicle.make ?? ''} ${vehicle.model ?? ''}`
          : '-'
      }
      </p>
      <p className='vehicle-sub-title'>{vehicle.trim != null ? vehicle.trim ?? '' : '-'}</p>

      {(dealership?.city != null && dealership?.state != null) && (
        <div className='vehicle-location'>
          <p className='location-wrapper'>
            <IconSVG.PlaceIcon /> {dealership.city}, {dealership.state}
          </p>

          <IconButton onClick={onRedirectGoogleMaps} className='direction-icon'>
            <IconSVG.DirectionIcon />
          </IconButton>
        </div>
      )}

      <div className='vehicle-price-wrapper'>
        <p>{price}</p>

        {paymentInfo != null && (
          <div className='monthly-payment-block'>
            <p className='per-month'>
              Est. {formatCurrency(paymentInfo.monthlyPayment)}/mo

              {tooltip != null && (
                <div className='prequalify-tooltip'>
                  {tooltip}
                </div>
              )}
            </p>

            <p className='cash-down'>
              Cash down {formatCurrency(paymentInfo.cashDown)}
            </p>
          </div>
        )}
      </div>

      <div className='divider' />

      <div className='vehicle-vin-stock-wrapper'>
        <p className='brief-info-item'>
          VIN: <span>{vehicle.vin}</span>
        </p>

        <p className='brief-info-item'>
          Stock: <span>{vehicle.stock ?? '-'}</span>
        </p>
      </div>

      <GetStartedButton
        nameInLayout='GetStartedButton'
        dealerId={vehicle.dealerId}
        vehicle={vehicle}
      />

      <GetPrequalifiedComponent
        dealerId={vehicle.dealerId}
        buttonProps={{
          text: 'Get pre-qualified'
        }}
      />

      <div
        className='cn-button-container'
        data-vin={vehicle.vin}
        data-page-type='vdp'
        data-type='used'
      />

      <Button
        variant='outlined'
        onClick={onClick}
        className='question-button'
      >
        Have questions? Ask us!
      </Button>
    </div>
  )
}

export default WithLocationContent
