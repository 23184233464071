import { GetPrequalifiedResponse } from 'website/api/types/financing'

interface PrequalifyProps extends GetPrequalifiedResponse<number> {
  price: number | null
}

export interface CalculatedPrequalify {
  monthlyPayment: number
  cashDown: number
}

export const calculatePrequalify = ({
  term,
  apr,
  downPayment,
  price
}: PrequalifyProps): CalculatedPrequalify | null => {
  if (term == null || price === null) {
    return null
  }

  const monthlyPrice = price / term
  const monthlyPayment = Math.round((monthlyPrice) + (monthlyPrice * apr / 100))

  return {
    monthlyPayment,
    cashDown: downPayment ?? 0
  }
}

interface GetTooltipProps {
  term: number | null
  apr: number | null
}

export const getTooltip = (props: GetTooltipProps): string => {
  if (props == null) {
    return ''
  }

  return `*Estimated monthly payment based on ${props.term} months loan at ${props.apr}% APR.`
}
