import AppearanceProtection from 'website/components/icons/coverages/AppearanceProtection'
import GAPInsurance from 'website/components/icons/coverages/GAPInsurance'
import ServiceContract from 'website/components/icons/coverages/ServiceContract'
import TheftInsurance from 'website/components/icons/coverages/TheftInsurance'
import TireWheel from 'website/components/icons/coverages/TireWheel'
import SmallChecked from 'website/components/icons/SmallChecked'
import TripleDots from 'website/components/icons/TripleDots'
import ChatIcon from 'website/components/icons/chat'
import SendIcon from 'website/components/icons/send-icon'
import CloseIcon from 'website/components/icons/close'
import ArrowDown from 'website/components/icons/arrowDown'
import Phone from 'website/components/icons/paymentShare/Phone'
import Email from 'website/components/icons/paymentShare/Email'
import Location from 'website/components/icons/paymentShare/Location'
import IconSVGTachometer from 'website/components/icons/IconSVGTachometer'
import IconSVGMotor from 'website/components/icons/IconSVGMotor'
import IconSVGAppriseYourCar from 'website/components/icons/IconSVGAppriseYourCar'
import IconSVGSellTradeIcon from 'website/components/icons/IconSVGSellTradeIcon'
import IconSVGGetPaid from 'website/components/icons/IconSVGGetPaid'
import IconSVGPersonalizationImage from './IconSVGPersonalizationImage'
import IconSVGPersonalData from './IconSVGPersonalData'
import IconSVGNoImpactImage from './IconSVGNoImpactImage'
import IconSVGCheckEllipse from './IconSVGCheckEllipse'
import IconSVGSuccessFinancingForm from './IconSVGSuccessFinancingForm'
import IconSVGFinancingAlarm from './IconSVGFinancingAlarm'
import IconSVGFinancingCash from './IconSVGFinancingCash'
import ArrowRight from './arrowRight'
import SmileEmojiSVG from './SmileEmoji'
import DirectionIcon from './DirectionIcon'
import PlaceIcon from './PlaceIcon'
import CheckIcon from './WebsiteCheckIcon'

const IconSVG = (): null => null

IconSVG.AppearanceProtection = AppearanceProtection
IconSVG.ArrowDown = ArrowDown
IconSVG.Chat = ChatIcon
IconSVG.Close = CloseIcon
IconSVG.Email = Email
IconSVG.GAPInsurance = GAPInsurance
IconSVG.Location = Location
IconSVG.Motor = IconSVGMotor
IconSVG.Phone = Phone
IconSVG.Send = SendIcon
IconSVG.ServiceContract = ServiceContract
IconSVG.SmallChecked = SmallChecked
IconSVG.Tachometer = IconSVGTachometer
IconSVG.TireWheel = TireWheel
IconSVG.TheftInsurance = TheftInsurance
IconSVG.TripleDots = TripleDots
IconSVG.AppriseYourCar = IconSVGAppriseYourCar
IconSVG.SellTradeIcon = IconSVGSellTradeIcon
IconSVG.GetPaid = IconSVGGetPaid
IconSVG.PersonalizationImage = IconSVGPersonalizationImage
IconSVG.PersonalData = IconSVGPersonalData
IconSVG.NoImpactImage = IconSVGNoImpactImage
IconSVG.CheckEllipse = IconSVGCheckEllipse
IconSVG.SuccessFinancingForm = IconSVGSuccessFinancingForm
IconSVG.FinancingAlarm = IconSVGFinancingAlarm
IconSVG.FinancingCash = IconSVGFinancingCash
IconSVG.ArrowRight = ArrowRight
IconSVG.SmileEmoji = SmileEmojiSVG
IconSVG.DirectionIcon = DirectionIcon
IconSVG.PlaceIcon = PlaceIcon
IconSVG.WebsiteCheckIcon = CheckIcon

export default IconSVG
