import { type KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useParams } from 'react-router-dom'
import { useRefUpdater } from '@carfluent/common'

import apiProvider from 'website/api/apiProvider'
import type { MessageProps } from 'website/components/Messenger/components/Message'
import type { Lead } from 'website/api/types/leads'
import SharedStateHook, { defaultInstance, StoreBranches } from 'website/store'

import { type UseChatProps, type UseChatPropsReturn, KeyCode } from './types'
import isRecaptchaSuccess from 'website/utils/validation/isRecaptchaSuccess'

let customMessageId = -1

export interface PendingMessageRef {
  value: string
  shouldSendMeta: boolean
}

const getDefaultMessage = (): MessageProps => ({
  id: --customMessageId,
  isReceived: true,
  isSending: false,
  message: 'Hello 👋 How can I assist you?'
})

const getCallRequestedMessage = (): MessageProps => ({
  id: --customMessageId,
  isReceived: true,
  isSending: false,
  message: 'We received your call request. One of our support team members will contact you shortly.'
})

const useZipCodeState = SharedStateHook<Store.ZipCodeLocationState>(StoreBranches.ZipCodeLocation)

const FIRST_TYPING_DELAY = 1000

export const useChat = ({
  threadId,
  isOpened,
  defaultMessages,
  onLastMessage
}: UseChatProps): UseChatPropsReturn => {
  const [value, setValue] = useState('')
  const [isRequestCallOpened, setIsRequestCallOpened] = useState(false)
  const [messages, setMessages] = useState<MessageProps[]>([])
  const [isFirstTyping, setIsFirstTyping] = useState(true)
  const [zipCodeLocation] = useZipCodeState(defaultInstance(StoreBranches.ZipCodeLocation))
  const [isSending, setIsSending] = useState(false)
  const firstOpenedRef = useRef(false)
  const sendingMessageRef = useRef(false)
  const pendingMessageRef = useRef<PendingMessageRef | null>(null)
  const { vehicleId } = useParams<{ vehicleId: string }>()

  const isDisabledSend = value.trim() === ''

  const refMessages = useRef<MessageProps[]>(messages)
  const refChatContent = useRef<HTMLDivElement>(null)
  const refChatContainer = useRef<HTMLDivElement>(null)
  const refRecaptcha = useRef<ReCAPTCHA>(null)
  const vehicleIdRef = useRefUpdater(vehicleId)

  // ========================================== //
  //                   HANDLERS                 //
  // ========================================== //

  const onSetMessage = useCallback((message) => {
    const next = [message, ...refMessages.current]
    refMessages.current = next
    setMessages(next)
  }, [])

  const onSendMessageToBot = useCallback(async (message: string, shouldSendMeta: boolean = false) => {
    if (threadId == null) {
      return
    }

    const { content } = await apiProvider.crm.createBotMessage(
      threadId,
      message,
      shouldSendMeta ? vehicleIdRef.current : undefined
    )

    onSetMessage({
      id: --customMessageId,
      message: content,
      isSending: false,
      isReceived: true
    })

    setIsSending(false)
    sendingMessageRef.current = false
  }, [onSetMessage, threadId])

  const onSubmitRequestCall = useCallback(async (lead: Lead) => {
    onSetMessage(getCallRequestedMessage())

    if (threadId == null) {
      return
    }

    const isValidRecaptcha = await isRecaptchaSuccess(refRecaptcha)

    if (isValidRecaptcha) {
      await apiProvider.crm.createLead({
        threadId,
        locationLatitude: zipCodeLocation.latitude,
        locationLongitude: zipCodeLocation.longitude,
        ...lead
      })
    }
  }, [zipCodeLocation, threadId])

  const sendMessage = (value: string, shouldSendMeta: boolean = false): void => {
    onSetMessage({
      id: --customMessageId,
      message: value,
      isSending: false
    })

    setIsSending(true)

    if (threadId == null && pendingMessageRef.current == null) {
      pendingMessageRef.current = {
        value,
        shouldSendMeta
      }
    }

    if (!sendingMessageRef.current) {
      sendingMessageRef.current = true
      void onSendMessageToBot(value, shouldSendMeta)
    }

    setValue('')
  }

  const onSend = (): void => {
    if (isDisabledSend) {
      return
    }

    sendMessage(value)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    const isEnterKey = e.key === KeyCode.Enter

    if (isEnterKey) {
      e.preventDefault()

      const isBrakeLine = e.metaKey || e.altKey || e.shiftKey || e.ctrlKey

      if (isBrakeLine) {
        setValue(value + '\n')
      } else {
        onSend()
      }
    }
  }

  // ========================================== //
  //                   EFFECTS                  //
  // ========================================== //

  useEffect(() => {
    const lastMessage = messages[0] ?? null

    if (lastMessage?.isReceived === true) {
      onLastMessage(lastMessage)
    }

    // if there is only one message, it is default message with question list. we should scroll to the top message.
    if (messages.length === 1) {
      const scrollEl = document.querySelector('.message-content')
      scrollEl?.scrollIntoView()
    }
  }, [onLastMessage, messages])

  useEffect(() => {
    if (firstOpenedRef.current || (defaultMessages != null && defaultMessages.length > 0)) {
      return
    }

    firstOpenedRef.current = true
    setIsFirstTyping(true)

    const timeout = setTimeout(() => {
      onSetMessage(getDefaultMessage())
      setIsFirstTyping(false)
    }, FIRST_TYPING_DELAY)

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpened, onLastMessage, defaultMessages])

  useEffect(() => {
    if (pendingMessageRef.current == null || threadId == null) {
      return
    }

    sendingMessageRef.current = true
    void onSendMessageToBot(pendingMessageRef.current.value, pendingMessageRef.current.shouldSendMeta)
    pendingMessageRef.current = null
  }, [onSendMessageToBot, threadId])

  useEffect(() => {
    if (defaultMessages == null || defaultMessages.length === 0) {
      return
    }

    firstOpenedRef.current = true
    setIsFirstTyping(false)
    refMessages.current = [getDefaultMessage(), ...defaultMessages].reverse()
    setMessages(refMessages.current)
  }, [defaultMessages])

  useEffect(() => {
    const chatContainer = refChatContainer.current
    const chatContent = refChatContent.current

    if ((chatContainer == null) || (chatContent == null)) return

    const handleScrollContent = (e: WheelEvent): void => {
      e.preventDefault()

      if (chatContent != null) {
        chatContent.scrollTop += e.deltaY
      }
    }

    const handleScrollContainer = (e: WheelEvent): void => {
      e.preventDefault()
    }

    chatContent.addEventListener('wheel', handleScrollContent, { passive: false })
    chatContainer.addEventListener('wheel', handleScrollContainer, { passive: false })

    return () => {
      chatContent.removeEventListener('wheel', handleScrollContent)
      chatContainer.removeEventListener('wheel', handleScrollContainer)
    }
  }, [])

  // ========================================== //

  return {
    setIsRequestCallOpened,
    isRequestCallOpened,
    isSending,
    isFirstTyping,
    messages,
    value,
    setValue,
    onKeyDown,
    onSend,
    sendMessage,
    onSubmitRequestCall,
    isDisabledSend,
    refChatContent,
    refChatContainer,
    refRecaptcha
  }
}
