import { css } from '@emotion/css'

export default css`
  background: #F7F8FA;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  
  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;

    .title {
      color: #222429;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .content {
      color: rgba(34, 36, 41, 0.80);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .content-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222429;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding: 10px;
    background: #EBECF0;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      g {
        opacity: 1;
      }

      path {
        stroke: var(--mainColor);
      }
    }
  }
`
